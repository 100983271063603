<template>
<div class="page" v-if="load">
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-success" style="width: 3rem; height: 3rem;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  <div class="page" v-if="!load">
  <data-form2
    :key="formKey"
    :data="data"
    :formFields="formFields"
    @save="saveData"
    @cancel="cancel"
    @changed="inputChanged"
  />
  <hr>
  <form-futher :key="data['id'] + 'foother'" v-if="data" 
    :createdBy="data['createdBy']"
    :created="data['createdAt']"
    :updatedBy="data['updatedBy']"
    :updated="data['updatedAt']"
    />
  </div>
</template>

<script>
import DataForm2 from "../widgets/DataForm2.vue";
import FormFuther from "../users/FormFuther.vue";
import CustomersDataService from "../../services/customers.service";
import CountriesDataService from "../../services/countries.service";
import SefEnabledCustomersDataService from "../../services/sef-customers.service";

export default {
  name: "customers-form",
  props: ["data"],
  components: {
    DataForm2,
    FormFuther,
  },
  data() {
    return { message: "", formKey: 0, load: false, listKey: 0, sef: null,};
  },
  computed: {
    formFields() {
      return {
        rows: [
          // [{ id: { type: "hidden" } }],
          [
            { id: { label: "Redni Broj", type: "number", disabled: true } },
            {
              type: {
                label: "Tip",
                type: "dropdown",
                url: "/customer-types",
                empty: "true",
              },
            },
            {
              status: {
                label: "Status",
                type: "dropdown",
                list: [
                  { id: "Active", name: "Aktivan" },
                  { id: "Inactive", name: "Neaktivan" },
                ],
              },
            },
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              name: {
                label:
                  this.data && this.data.type != 3 ? "Naziv" : "Ime i prezime",
                type: "text",
              },
            },
          ],
          [{ divider: { type: "divider" } }],
          [
            {
              pib: {
                label: this.data && this.data.type != 3 ? "PIB" : "JMBG",
                type: "text",
                blockquote: `${this.data && this.data.pib ? (this.sef && this.sef.data.pib ? '' : 'Nije u sistemu eFaktura!') : ''}`
              },
            },
            this.data && this.data.type != 3
              ? { mb: { label: "Maticni broj", type: "text" } }
              : {},
            this.data && this.data.type != 3
              ? { bank_account: { label: "Tekući račun", type: "text" } }
              : {},
          ],
          [
            { email: { label: "e-Mail", type: "email" } },
            { phone: { label: "Telefon", type: "phone" } },
          ],
          [
            { address: { label: "Adresa", type: "text" } },
            { city: { label: "Grad", type: "text" } },
            { zip: { label: "Posta", type: "text" } },
            { country: { 
              key: this.listKey,
              label: "Drzava", 
              multiple: false,
              type: "dropdown",
              list: this.countries ? this.countries : [],
             } },
          ],
          [{ divider: { type: "divider" } }],
          [
            { saop: { label: "SAOP", type: "number" } },
            { valuta: { label: "Valuta dana", type: "number" } },
            {},
            {},
          ],
          [{ divider: { type: "divider" } }],
        ],
      };
    },
  },
  methods: {
    inputChanged(e, name) {
      if (name == 'pib' && e) {
        SefEnabledCustomersDataService.get(e).then((sef) => {
          this.sef = sef;
        });
      }
    },
    showError(text) {
      this.$store.commit("app/showAlert", {
        type: "danger",
        message: text,
      });
    },
    async validate(data) {
      let msg = "";
      /// get contry code for Serbia
      let country = this.countries.find((c) => c.name == "Serbia");
      if (data.country == country.id && !data.pib) {
        msg += data.type == 3 ? "Polje JMBG je obavezno\n" :"Polje PIB je obavezno\n";
        // return false;
      }

      /// Check that PIB is numeric with regex
      if (data.country == country.id && data.pib && !/^\d+$/.test(data.pib)) {
        msg += "Neispravna vrednost za PIB. Samo brojevi su dozvoljeni\n";
        // return false;
      }

      if (data.country == country.id && !data.mb && data.type != 3) {
        msg += "Polje Maticni broj je obavezno\n";
        // return false;
      } 

      /// Check that MB is numeric with regex
      if (data.country == country.id && data.mb && !/^\d+$/.test(data.mb)) {
        msg += "Neispravna vrednost za Maticni broj. Samo brojevi su dozvoljeni\n";
        // return false;
      }

      if (!data.saop) {
        msg += "Polje SAOP je obavezno\n";
        // return false;
      }

      if (data.type == 3 && data.pib && data.pib.length != 13) {
        msg += "Neispravna vrednost za JMBG. Obavezna duzina je 13\n";
        // return false;
      }

      if (data.type == 3 && !data.email) {
        msg += "Polje e-Mail je obavezno\n";
        // return false;
      }

      if (data.type == 3 && !data.phone) {
        msg += "Polje telefon je obavezno\n";
        // return false;
      }
      /// check if pib exist in DB
      await CustomersDataService.findByPib(data.pib)
        .then((response) => {
          if (response.data && response.data.id != data.id) {
            msg += "PIB vec postoji u bazi\n";
          }
        })
        .catch((e) => {
          console.log(e);
        });
      /// check if mb exist in DB
      await CustomersDataService.findByMb(data.mb)
        .then((response) => {
          if (response.data && response.data.id != data.id) {
            msg += "Maticni broj vec postoji u bazi\n";
          }
        })
        .catch((e) => {
          console.log(e);
        });
      if (msg != "") {
        this.showError(msg);
        return false;
      }
      return true;
    },
    async saveData(newData) {
      if (await this.validate(newData)) {
        if (newData && newData.id) {
          this.update(newData);
        } else {
          this.create(newData);
        }
      }
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      CustomersDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Korisnik je izmenjen!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      CustomersDataService.create(newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Korisnik je kreiran!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
  async mounted() {
    this.load = true;
    if (this.data && this.data.pib) {
      this.sef = await SefEnabledCustomersDataService.get(this.data.pib);
    }
    CountriesDataService.getFullList().then((countries) => {
      this.countries = countries.data.rows.map((r) => {return {id: r.name, name: r.name}});
      // console.log({w: this.countries});
      this.load = false;
      // this.formKey++;
      this.listKey++;
    })
  },
  created() {},
  watch: {},
};
</script>