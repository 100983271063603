<template>
  <div class="list row">
    <h2>Kupci</h2>
    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      >
        Osveži tabelu
      </button>
      <button type="button" class="btn btn-success bi bi-plus" @click="addData">
        Dodaj novog kupca
      </button>
    </div>
    <data-table
      url="customers"
      :columns="columns"
      :templates="templates"
      :headings="headers"
      :actions="actions"
      ref="datatable"
      @yes="deleteData"
      name="Customers"
      @loaded="onLoaded"
    >
      <template v-slot:tableExtraOptions>
        <vue-excel-xlsx
          class="ms-5 btn btn-warning bi bi-download"
          :data="jsonData['data']"
          :columns="jsonData['columns']"
          file-name="kupci"
          :file-type="'xlsx'"
          :sheet-name="'Export'"
        >
          Download
        </vue-excel-xlsx>
      </template>
    </data-table>
  </div>
</template>

<script>
import CustomersDataService from "../../services/customers.service";
import DataTable from "../widgets/DataTable.vue";
import CustomerForm from "./CustomerForm.vue";
import utils from "../../utils/common";

export default {
  name: "customers",
  components: {
    DataTable,
  },
  data() {
    return {
      jsonData: [],
      columns: [
        "id",
        "customer-type.name",
        "name",
        "email",
        "phone",
        "pib",
        "address",
        "city",
        "zip",
        "country",
        "status",
        "saop",
        "edit",
      ],
      headers: {
        id: "RB.",
        "customer-type.name": "Tip",
        name: "Naziv",
        email: "e-Mail",
        phone: "telefon",
        pib: "PIB",
        address: "Adresa",
        city: "Grad",
        zip: "Posta",
        country: "Drzava",
        status: "Status",
        saop: "SAOP",
        edit: "",
      },
      templates: {},
      actions: {
        edit: { commp: CustomerForm, icon: "bi-pencil" },
        delete: {
          action: this.deleteData,
          color: "danger",
          icon: "bi-trash",
        },
        // print: null,
      },
      currentTutorial: null,
      currentIndex: -1,
      title: "",
    };
  },
  methods: {
    async refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addData() {
      this.$store.commit("app/showModal", {
        modal: CustomerForm,
        params: { data: {country: 'Serbia', valuta: 7}, title: "Dodaj novog kupca" },
      });
    },
    deleteData(data) {
      // CustomersDataService.delete(id)
      //   .then((response) => {
      //     this.$store.commit("app/showAlert", {
      //       type: "success",
      //       message: response.data.message,
      //     });
      //     this.$store.commit("app/hideModal");
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     this.$store.commit("app/showAlert", {
      //       type: "danger",
      //       message: e,
      //     });
      //   });
      utils.showDialog(
            data,
            "Da li ste sigurni da zelite da deaktivirate kupca?",
            "Deaktiviranje kupca: " + data.id,
            () => {
                CustomersDataService.update(data.id, {...data, status: 'Inactive'})
                  .then(() => {
                    this.$store.commit("app/showAlert", {
                      type: "success",
                      message: "Kupac je deaktiviran!",
                    });
                    this.$store.commit("app/hideModal");
                  })
                  .catch((e) => {
                    this.$store.commit("app/showAlert", {
                      type: "danger",
                      message: e,
                    });
                  });
            }
        );
      
    },
    onLoaded(data) {
      this.jsonData["data"] = data.data.rows.map((r) => {
        return {
          ...r,
          "customer-type": r["customer-type"] ?? {},
        };
      });
      this.jsonData["columns"] = Object.entries(this.headers)
        .map((k) => {
          if (k[1])
            return {
              label: k[1],
              field: k[0],
            };
        })
        .filter((o) => !!o);
    },
  },
};
</script>

<style>
.list {
  text-align: left;
  /* max-width: 750px; */
  margin: auto;
}

.Customers_table_row > td:nth-child(2) {
  min-width: 120px;
  max-width: 250px;
  font-size: small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Customers_table_row > td:nth-child(4),
.Customers_table_row > td:nth-child(8),
.Customers_table_row > td:nth-child(7) {
  min-width: 120px;
  max-width: 450px;
  font-size: small;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Customers_table_row > td:nth-child(3) {
  min-width: 300px;
  max-width: 450px;
  font-size: small;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
